/**
 * 外注先
 */
import { isBlank } from '@/utilities/typing';

class Subcontractor {
    subcontractor_id;
    subcontractor_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.subcontractor_id = properties.subcontractor_id;
        this.subcontractor_name = properties.subcontractor_name;
    }
}

export default Subcontractor;
