/**
 * 外注費
 */
import { isBlank } from '@/utilities/typing';
import { displayMonth } from '@/utilities/month'
import Subcontractor from './subcontractor';

class SubcontractCost {
    subcontract_cost_id;
    subcontractor;
    month;
    price;
    note;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.subcontract_cost_id = properties.subcontract_cost_id;
        this.subcontractor = new Subcontractor(properties.subcontractor);
        this.month = properties.month;
        this.price = properties.price;
        this.note = properties.note;
    }

    get month_display() {
        return displayMonth(this.month);
    }
}

export default SubcontractCost;
