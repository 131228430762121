<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-line-fill"></i> 案件サマリー</h1>
        <router-link class="btn btn-outline-secondary" :to="{name: 'ProfitStatsProject'}"><i class="bi bi-reply"></i> 戻る</router-link>
    </header>

    <div class="row justify-content-end mb-3">
        <div class="col-md-12 col-18 d-flex gap-3">
            <div class="btn-group">
                <button class="btn" :class="{'btn-secondary': period === 1, 'btn-outline-secondary': period !== 1}" @click="fetch(1)" :disabled="loading">通算</button>
                <button class="btn" :class="{'btn-secondary': period === 2, 'btn-outline-secondary': period !== 2}" @click="fetch(2)" :disabled="loading">今期</button>
                <button class="btn" :class="{'btn-secondary': period === 3, 'btn-outline-secondary': period !== 3}" @click="fetch(3)" :disabled="loading">期間指定</button>
            </div>
            <div class="input-group">
                <form-input-month v-model="month_from" :disabled="period !== 3 || loading" @validlyChange="fetch(3)"></form-input-month>
                <span class="input-group-text">〜</span>
                <form-input-month v-model="month_to" :disabled="period !== 3 || loading" @validlyChange="fetch(3)"></form-input-month>
            </div>
        </div>
    </div>

    <section class="section">
        <div class="row mb-3 align-items-end">
            <div class="col-8">
                <label class="form-label">案件名</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="12"></text-loader></div>
                <div v-else class="form-readonly">{{ project.project_name }}</div>
            </div>
            <div class="col-6">
                <label class="form-label">顧客名</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="10"></text-loader></div>
                <div v-else class="form-readonly">{{ project.customer.customer_name }}</div>
            </div>
            <div class="col-6">
                <label class="form-label">案件期間</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="4"></text-loader></div>
                <div v-else class="form-readonly">{{ project.available_period }}</div>
            </div>
            <div class="col-4">
                <label class="form-label">売上タイプ</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="6"></text-loader></div>
                <div v-else class="form-readonly">{{ project.project_type_label }}</div>
            </div>
        </div>

        <table class="table table-layout-fixed">
            <tbody>
                <tr class="table-light text-center">
                    <th>売上</th>
                    <th>労務費</th>
                    <th>外注費</th>
                    <th>諸経費</th>
                    <th>粗利</th>
                    <th>粗利率</th>
                </tr>
                <tr v-if="!loading">
                    <td class="text-end fw-bold">{{ $helper.number(total_sales_price) }} 円</td>
                    <td class="text-end">{{ $helper.number(total_labor_cost) }} 円</td>
                    <td class="text-end">{{ $helper.number(total_subcontract_cost) }} 円</td>
                    <td class="text-end">{{ $helper.number(total_incidental_cost) }} 円</td>
                    <td class="text-end fw-bold">{{ $helper.number(total_gross_profit) }} 円</td>
                    <td class="text-end fw-bold" :class="profit_rate_class(total_sales_price, total_gross_profit)">{{ total_gross_profit_rate }}</td>
                </tr>
                <tr v-else>
                    <td class="text-end fw-bold"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end fw-bold"><text-loader :width="6"></text-loader></td>
                    <td class="text-end fw-bold"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="section">
        <h2 class="section-title">月別収支</h2>
        <table class="table">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">年月</th>
                    <th class="text-center">売上</th>
                    <th class="text-center">労務費</th>
                    <th class="text-center">外注費</th>
                    <th class="text-center">諸経費</th>
                    <th class="text-center">粗利</th>
                    <th class="text-center">粗利率</th>
                    <th class="text-center">状態</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(10)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-center"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
            <tbody v-else-if="profits.length === 0">
                <td colspan="8" class="text-center p-3">該当データがありません</td>
            </tbody>
            <tbody v-else>
                <tr v-for="profit of profits" :key="profit.profit_id">
                    <td class="">{{ profit.month_display }}</td>
                    <td class="text-end">{{ $helper.number(profit.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(profit.labor_cost) }}</td>
                    <td class="text-end">{{ $helper.number(profit.subcontract_cost) }}</td>
                    <td class="text-end">{{ $helper.number(profit.incidental_cost) }}</td>
                    <td class="text-end">{{ $helper.number(profit.gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(profit.sales_price, profit.gross_profit)">{{ profit.gross_profit_rate }}</td>
                    <td class="text-center">
                        <span v-if="profit.is_calculating" class="text-warning">計算中</span>
                        <span v-else-if="profit.is_fixed">確定</span>
                        <span v-else class="text-danger">未確定</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="section" v-if="loading">
        <h2 class="section-title"><text-loader :width="10"></text-loader></h2>
        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center"><text-loader :width="6"></text-loader></th>
                    <th class="text-center">稼働時間</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="i in Array(3)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="section" v-else-if="project.project_type === ProjectType.ONE_OFF">
        <h2 class="section-title">フェーズ別稼働</h2>
        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">フェーズ</th>
                    <th class="text-center">金額</th>
                    <th class="text-center">稼働時間</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="work_summary of phase_work_summaries" :key="work_summary.phase.phase_id">
                    <td class="">{{ work_summary.phase.phase_name }}</td>
                    <td class="text-end">{{ $helper.number(work_summary.phase.sales_price) }}</td>
                    <td class="text-end">{{ $helper.clock(work_summary.work_time) }}</td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="section" v-else>
        <h2 class="section-title">作業カテゴリ別稼働</h2>
        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">作業カテゴリ</th>
                    <th class="text-center">稼働時間</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="work_summary of work_category_work_summaries" :key="work_summary.work_category.work_category_id">
                    <td class="">{{ work_summary.work_category.category_name }}</td>
                    <td class="text-end">{{ $helper.clock(work_summary.work_time) }}</td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="section">
        <h2 class="section-title">月別稼働</h2>
        <table class="table table-bordered">
            <thead class="table-dark text-center">
                <tr v-if="loading">
                    <th></th>
                    <th v-for="i in Array(5)" :key="i"><text-loader :width="6"></text-loader></th>
                    <th>合計</th>
                </tr>
                <!--ヘッダ行-->
                <tr v-else>
                    <th></th>
                    <th v-for="user of work_users" :key="user.user_id">{{ user.user_name }}</th>
                    <th>合計</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <template v-if="loading">
                    <tr v-for="i in Array(6)" :key="i">
                        <td><text-loader :width="12"></text-loader></td>
                        <td v-for="i in Array(6)" :key="i"><text-loader :width="3"></text-loader></td>
                    </tr>
                </template>
                <template v-else>
                    <!--明細行-->
                    <tr v-for="month of this.work_months" :key="month">
                        <td>{{ displayMonth(month) }}</td>
                        <td v-for="user of work_users" :key="user.user_id">
                            <template v-if="loading">
                                <text-loader :width="6"></text-loader>
                            </template>
                            <template v-else-if="user.user_id in work_map && month in work_map[user.user_id]">
                                {{ $helper.clock(work_map[user.user_id][month]) }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <!--行合計-->
                        <td>
                            <template v-if="loading">
                                <text-loader :width="6"></text-loader>
                            </template>
                            <template v-else-if="month in month_total_map">
                                {{ $helper.clock(month_total_map[month]) }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot class="table-light text-center">
                <tr v-if="loading">
                    <th>合計</th>
                    <th v-for="i in Array(5)" :key="i"><text-loader :width="6"></text-loader></th>
                    <th><text-loader :width="6"></text-loader></th>
                </tr>
                <!--合計行-->
                <tr v-else>
                    <th>合計</th>
                    <td v-for="user of work_users" :key="user.user_id">
                        <template v-if="work_loading">
                            <text-loader :width="6"></text-loader>
                        </template>
                        <template v-else-if="user.user_id in user_total_map">
                            {{ $helper.clock(user_total_map[user.user_id]) }}
                        </template>
                        <template v-else>
                            -
                        </template>
                    </td>
                    <!--総合計-->
                    <td>
                        {{ $helper.clock(work_total) }}
                    </td>
                </tr>
            </tfoot>
        </table>
    </section>

    <section class="section">
        <h2 class="section-title">外注費</h2>
        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">年月</th>
                    <th class="text-center">外注先</th>
                    <th class="text-center">摘要</th>
                    <th class="text-center">金額</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(5)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
            <tbody v-else-if="subcontract_costs.length === 0">
                <td colspan="4" class="text-center p-3">該当データがありません</td>
            </tbody>
            <tbody v-else>
                <tr v-for="cost of subcontract_costs" :key="cost.subcontract_cost_id">
                    <td class="">{{ cost.month_display }}</td>
                    <td class="">{{ cost.subcontractor.subcontractor_name }}</td>
                    <td class="">{{ cost.note }}</td>
                    <td class="text-end">{{ $helper.number(cost.price) }}</td>
                </tr>
            </tbody>
            <tfoot class="table-light">
                <tr>
                    <td>合計</td>
                    <td></td>
                    <td></td>
                    <td v-if="loading" class="text-end"><text-loader :width="6"></text-loader></td>
                    <td v-else class="text-end">{{ $helper.number(total_subcontract_cost) }}</td>
                </tr>
            </tfoot>
        </table>
    </section>

    <section class="section">
        <h2 class="section-title">諸経費</h2>
        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">年月</th>
                    <th class="text-center">摘要</th>
                    <th class="text-center">金額</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(5)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
            <tbody v-else-if="incidental_costs.length === 0">
                <td colspan="3" class="text-center p-3">該当データがありません</td>
            </tbody>
            <tbody v-else>
                <tr v-for="cost of incidental_costs" :key="cost.incidental_cost_id">
                    <td class="">{{ cost.month_display }}</td>
                    <td class="">{{ cost.note }}</td>
                    <td class="text-end">{{ $helper.number(cost.price) }}</td>
                </tr>
            </tbody>
            <tfoot class="table-light">
                <tr>
                    <td>合計</td>
                    <td></td>
                    <td v-if="loading" class="text-end"><text-loader :width="6"></text-loader></td>
                    <td v-else class="text-end">{{ $helper.number(total_incidental_cost) }}</td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import Project from "@/models/entities/project";
import Profit from "@/models/entities/profit";
import SubcontractCost from "@/models/entities/subcontract-cost";
import IncidentalCost from "@/models/entities/incidental-cost";
import ProjectType from "@/models/enums/project-type";
import TextLoader from '@/components/tools/TextLoader';
import { displayMonth, startOfPeriod, endOfPeriod } from '@/utilities/month'

export default {
    name: 'ProfitStatsProjectDetail',
    components: {
        FormInputMonth,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            project_id: parseInt(this.$route.params.project),
            loading: true,
            period: 1,
            project: new Project(),
            profits: [],


            work_map: {},
            user_total_map: {},
            month_total_map: {},
            work_user_ids: [],
            work_users: [],
            work_months: [],
            work_total: 0,

            phase_work_summaries: [],
            work_category_work_summaries: [],
            subcontract_costs: [],

            displayMonth,
            ProjectType,
        }
    },
    computed: {
        total_sales_price() {
            return this.profits.reduce((total, profit) => total + profit.sales_price, 0);
        },
        total_labor_cost() {
            return this.profits.reduce((total, profit) => total + profit.labor_cost, 0);
        },
        total_subcontract_cost() {
            return this.profits.reduce((total, profit) => total + profit.subcontract_cost, 0);
        },
        total_incidental_cost() {
            return this.profits.reduce((total, profit) => total + profit.incidental_cost, 0);
        },
        total_cost_total() {
            return this.profits.reduce((total, profit) => total + profit.cost_total, 0);
        },
        total_gross_profit() {
            return this.profits.reduce((total, profit) => total + profit.gross_profit, 0);
        },
        total_gross_profit_rate() {
            if (this.total_sales_price === 0) {
                return "-";
            } else {
                return (this.total_gross_profit * 100 / this.total_sales_price).toFixed(1) + '%';
            }
        },
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
    },
    mounted() {
        this.fetch(1);
    },
    methods: {
        fetch(period)
        {
            this.loading = true;
            this.period = period;

            let month_from = null;
            let month_to = null;
            if (this.period === 2) {
                month_from = startOfPeriod(this.$store.state.auth.company.settlement_month);
                month_to = endOfPeriod(this.$store.state.auth.company.settlement_month);
            } else if (this.period === 3) {
                month_from = this.month_from;
                month_to = this.month_to;
            }

            this.$http.get(`/stats/project/${this.project_id}`, {params: {month_from, month_to}})
            .then((response) => {
                this.project = new Project(response.data.project);
                this.profits = response.data.profits.map(row => new Profit(row));
                this.parse_work(response.data.work_summaries);
                this.phase_work_summaries = response.data.phase_work_summaries;
                this.work_category_work_summaries = response.data.work_category_work_summaries;
                this.subcontract_costs = response.data.subcontract_costs.map(row => new SubcontractCost(row));
                this.incidental_costs = response.data.incidental_costs.map(row => new IncidentalCost(row));
                this.loading = false;
            })
        },
        parse_work(work_summaries)
        {
            this.work_map = {};
            this.user_total_map = {};
            this.month_total_map = {};
            this.work_user_ids = [];
            this.work_users = [];
            this.work_months = [];
            this.work_total = 0;

            for (let row of work_summaries) {
                let user_id = row.user.user_id;
                let month = row.month;
                if (!(user_id in this.work_map)) {
                    this.work_map[user_id] = {};
                }
                this.work_map[user_id][month] = row.work_time;

                if (!(user_id in this.user_total_map)) {
                    this.user_total_map[user_id] = 0;
                }
                this.user_total_map[user_id] += row.work_time;

                if (!(month in this.month_total_map)) {
                    this.month_total_map[month] = 0;
                }
                this.month_total_map[month] += row.work_time;

                if (!this.work_user_ids.includes(user_id)) {
                    this.work_user_ids.push(user_id);
                    this.work_users.push(row.user);
                }

                if (!this.work_months.includes(month)) {
                    this.work_months.push(month);
                }

                this.work_total += row.work_time;
            }
        },
        profit_rate_class(sales_price, gross_profit)
        {
            if (sales_price === 0) {
                return null;
            } else {
                const rate = gross_profit * 100 / sales_price;
                if (rate >= this.$store.state.auth.company.profit_rate_safe) {
                    return "text-success";
                } else if (rate < this.$store.state.auth.company.profit_rate_danger) {
                    return "text-danger";
                } else {
                    return "text-warning";
                }
            }
        },
    },
}
</script>

<style scoped>
</style>
