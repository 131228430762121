/**
 * 諸経費
 */
import { isBlank } from '@/utilities/typing';
import { displayMonth } from '@/utilities/month'

class IncidentalCost {
    incidental_cost_id;
    month;
    price;
    note;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.incidental_cost_id = properties.incidental_cost_id;
        this.month = properties.month;
        this.price = properties.price;
        this.note = properties.note;
    }

    get month_display() {
        return displayMonth(this.month);
    }
}

export default IncidentalCost;
